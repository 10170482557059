function LoaderFallback(): JSX.Element {
  return (
    <div>
      <h1 style={{ margin: 0 }}>FRANKLIN HARVEY</h1>
      <div style={{ textAlign: "right" }}>0</div>
    </div>
  );
}

export default LoaderFallback;
